import { useEffect } from 'react';
// theme
import ThemeProvider from './theme';

import { workWithLocalStorage } from './auxiliares/funciones';

import Index from './plantilla/principal';
import Login from './paginas/Login';

// ----------------------------------------------------------------------

export default function App() {
  
    const usuario = workWithLocalStorage('get','usuario',null);

    /* useEffect(() => {
      if(usuario !== undefined){
        const unloadCallback = (event) => {
          event.preventDefault();
          event.returnValue = "";
          return "";
        };
        window.addEventListener("beforeunload", unloadCallback);
          return () => window.removeEventListener("beforeunload", unloadCallback);
      }

      return () =>{}

      }, []); */

  
    return (
      <ThemeProvider>
        { usuario === undefined ?
        <Login />
        :
        <Index />
        }
      </ThemeProvider>
    );
}
