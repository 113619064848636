import React from 'react';
// components
import Divider from "@mui/material/Divider";
import { Button, Card, CardHeader, CardContent, Grid } from '@mui/material';
import Swal from 'sweetalert2';

import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, compruebaForm } from '../../auxiliares/funciones';
import ClienteForm from './form/form';
import BonosClientes from './form/bonos';
import ChequesClientes from './form/cheques';
import { getBonos, getTiposPago, getServicios, getTrabajadores, getProductos } from '../../auxiliares/maestros';
import Recuadro from '../../componentes/Recuadro';
import ProductosClientes from './form/productos';

// ----------------------------------------------------------------------
export default function AltaForm(props) {
    const usuario = workWithLocalStorage('get','usuario',null);
    const Form = {vip:0,nombre:'',apellidos:'',dni:'',telefono:'',email:'',notas:''}
    const obligatorios = ['nombre','apellidos','telefono'];

    const [cliente, setCliente] = React.useState(Form);
    const [bonos, setBonos] = React.useState([]);
    const [cheques, setCheques] = React.useState([]);
    const [productos, setProductos] = React.useState([]);
    const [productosL, setProductosL] = React.useState([]);
    const [servicios, setServicios] = React.useState([]);
    const [listaBonos, setListaBonos] = React.useState([]);
    const [trabajadores, setTrabajadores] = React.useState([]);
    const [tiposPago, setTiposPago] = React.useState([]);
    const [cargado, setCargado] = React.useState(false);
    const [id,setId] = React.useState(props.params.id ? props.params.id : '0');

    React.useEffect(() => {
        async function cargaInicial(){
            if(!cargado){
              setListaBonos(await getBonos(usuario.token));
              setTiposPago(await getTiposPago(usuario.token));
              setServicios(await getServicios(usuario.token));
              setProductosL(await getProductos(usuario.token));
              setTrabajadores(await getTrabajadores(usuario.token));
                if(id !== '0'){
                    // eslint-disable-next-line
                    const bodyJson = JSON.stringify(buildBody(usuario.token,{ id:id }))
                    fetch(`${CONFIG.api}cliente/detalle`,{
                                method: 'POST',
                                body: bodyJson,
                                headers: {'Content-Type': 'application/json'}
                        })
                        .then(res => res.json())
                        .then((result) => {
                        if (result.error === 0 && !isEmpty(result.data)) {
                            setCliente(result.data);
                        }
                        props.showLoader(false);
                        setCargado(true);
                    })
                    fetch(`${CONFIG.api}cliente/bonos`,{
                                  method: 'POST',
                                  body: bodyJson,
                                  headers: {'Content-Type': 'application/json'}
                          })
                          .then(res => res.json())
                          .then((result) => {
                          if (result.error === 0 && !isEmpty(result.data)) {
                              setBonos(result.data);
                          }
                      })
                    fetch(`${CONFIG.api}cliente/cheques`,{
                                  method: 'POST',
                                  body: bodyJson,
                                  headers: {'Content-Type': 'application/json'}
                          })
                          .then(res => res.json())
                          .then((result) => {
                          if (result.error === 0 && !isEmpty(result.data)) {
                              setCheques(result.data);
                          }
                      })
                    fetch(`${CONFIG.api}cliente/productos`,{
                                  method: 'POST',
                                  body: bodyJson,
                                  headers: {'Content-Type': 'application/json'}
                          })
                          .then(res => res.json())
                          .then((result) => {
                          if (result.error === 0 && !isEmpty(result.data)) {
                              setProductos(result.data);
                          }
                      })
                }else{
                    props.showLoader(false);
                    setCargado(true);
                }
            }
        }
        cargaInicial();
      // eslint-disable-next-line
      },[cargado]);

    const Grabar = () =>{
        const valido = compruebaForm(Form,cliente,obligatorios);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true);
            const body = cliente;
            const bodyJson = JSON.stringify(buildBody(usuario.token,body))
            fetch(`${CONFIG.api}cliente/detalle`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Trabajador grabado!';
                if (result.error === 0) {
                    setId(result.data.id);
                    document.getElementById(`simple-tab-${props.index}`).getElementsByTagName( 'span' )[0].textContent = `Editar Cliente #${result.data.id} `;
                    setCargado(false);
                }else{
                    props.showLoader(false);
                    msg = result.errorMessage;
                }
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const Nuevo = () =>{
        setCliente(Form);
        setId('0')
        document.getElementById(`simple-tab-${props.index}`).getElementsByTagName( 'span' )[0].textContent = 'Alta Cliente ';
    }

    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        cliente[nombre] = valor
        setCliente(cliente);
    }

    const Activar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {
          tabla: "clientes",
          activo: 1,
          id: cliente.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Cliente activado!';
            if (result.error === 0) {
                setCargado(false);
            }else{
              props.showLoader(false);
              msg = result.errorMessage;
            }
            Swal.fire({
              title: msg,
              buttons: [{label: 'Ok'}]
            })
        })
    }

    const Borrar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {
            tabla: "clientes",
            activo: 0,
            id: cliente.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Cliente desactivado!';
                if (result.error === 0) {
                    setCargado(false);
                }else{
                    props.showLoader(false);
                    msg = result.errorMessage;
                }
                props.showLoader(false);
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
        })
    }

    return (
        <Page title="Alta Cliente" key={getUniqueKey()}>
            <Card  sx={{ maxWidth: '100% !important'}}>
                <CardHeader action={<>
                                    <Button variant="contained" color="success" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                                    onClick={()=>Grabar(null)}>
                                        Grabar
                                    </Button>
                                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                                    onClick={()=>Nuevo()}>
                                        Nuevo
                                    </Button>
                                    {cliente.id &&
                                    (cliente.activo === 1 ?
                                    <Button variant="contained" color="error" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:trash-2-outline" />}
                                    onClick={()=>Borrar()}>
                                        Borrar
                                    </Button>
                                    : <Button variant="contained" color="warning" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                    onClick={()=>Activar()}>
                                        Activar
                                    </Button>)
                                    }
                                    </>}
                        title={id === '0' ? "Alta Cliente" : `Editar Cliente #${id}`}
                />
                <Divider style={{ margin: '15px'}} />
                <CardContent>
                    {cargado &&
                      <ClienteForm showLoader={props.showLoader} cliente={cliente} actualizaCampo={actualizaCampo}/>
                    }
                </CardContent>
                <CardContent>
                    {(cargado && cliente.id) &&
                      <Recuadro titulo={'Bonos'}>
                        <BonosClientes showLoader={props.showLoader} trabajadores={trabajadores} idCliente={cliente.id} tiposPago={tiposPago} listaBonos={listaBonos} bonos={bonos} Actualiza={setCargado}/>
                      </Recuadro>
                    }
                    {(cargado && cliente.id) &&
                      <Recuadro titulo={'Cheques Regalo'} extrasx={{ marginTop: '30px !important'}}>
                        <ChequesClientes showLoader={props.showLoader} trabajadores={trabajadores} idCliente={cliente.id} tiposPago={tiposPago} servicios={servicios} cheques={cheques} Actualiza={setCargado}/>
                      </Recuadro>
                    }
                    {(cargado && cliente.id) &&
                      <Recuadro titulo={'Productos'} extrasx={{ marginTop: '30px !important'}}>
                        <ProductosClientes showLoader={props.showLoader} productos={productos} productosL={productosL} trabajadores={trabajadores} idCliente={cliente.id} tiposPago={tiposPago} Actualiza={setCargado}/>
                      </Recuadro>
                    }
                </CardContent>
            </Card>
        </Page>
    );
}
